import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { AgreementProduct, ChangeLog, ProductOrder } from 'types/data';
import { Supplier } from 'types/supplier';
import { WarrantyCase, WarrantyMessage } from 'types/warranty';
import { GlobalSettings } from 'views/GlobalSettings/models/globalSettings';

export interface DataState {
  suppliers: {
    list: Supplier[];
    selected: Supplier[];
    fetching: boolean;
  };
  errors: any;
  files: any;
  popover: HTMLButtonElement | null;
  product: string;
  order: ProductOrder | null;
  sorting: boolean | null;
  filtering: boolean | null;
  agreementWithoutProducts: boolean | null;
  supplier_name: string | null;
  globalSettingsChanges: boolean | null;
  globalSettingsForm: GlobalSettings | null;
  suggestedDateRange: string | null;
  changeLogs: ChangeLog[];
  agreementFields: any;
  tableFilter: {
    products: {
      stock_below_the_minimum: boolean | null;
      product_with_orders_submitted: boolean | null;
      product_with_orders_delivered: boolean | null;
      search: string | null;
    };
    production_demand: {
      stock_below_the_minimum: boolean | null;
      exclude_zero_plans: boolean | null;
      exclude_zero_orders: boolean | null;
      search: string | null;
    };
    price_agreements: {
      status_valid: boolean | null;
      status_invalid: boolean | null;
      status_expired: boolean | null;
      status_draft: boolean | null;
      status_future: boolean | null;
      agreement_price_missing: boolean | null;
      product_price_missing: boolean | null;
      search: string | null;
      no_agreement: boolean | null;
      date: {
        search: boolean | null;
        start: string | null;
        end: string | null;
      };
    };
    deliveries: {
      bound_with_invoices: boolean | null;
      submitted: boolean | null;
      delivered: boolean | null;
      search: string | null;
      date: {
        start: string | null;
        end: string | null;
      };
    };
    spare_parts: {
      with_spares: boolean | null;
      search: string | null;
    };
    documents: {
      search: string | null;
    };
    warranty_cases: {
      new_cases: boolean | null;
      open: boolean | null;
      open_by_bmf: boolean | null;
      updates: boolean | null;
      case_status: string | null;
      bmf_status: string | null;
      search: string | null;
      date: {
        start: string | null;
        end: string | null;
      };
    };
    merit_integration: {
      show_only_unbound: boolean | null;
      show_only_ignored: boolean | null;
      search: string | null;
    };
    invoices: {
      search: string | null;
      show_unpaid: boolean | null;
      date: {
        start: string | null;
        end: string | null;
      };
    };
    users: {
      search: string | null;
    };
    orders: {
      search: string | null;
      delivered: boolean | null;
      undelivered: boolean | null;
      supplier_status: number | null;
    };
    order_products: {
      with_deliveries: boolean | null;
      with_intakes: boolean | null;
    };
  };
  warrantyDetailChanges: boolean | null;
  warrantyDetailsForm: WarrantyCase | null;
  warrantyDetailUpdates: {
    read: boolean;
    message: boolean;
    upload: boolean;
    expertise: boolean;
  };
  detailCurrentTab: string | null;
  fileDrag: boolean | null;
  userDetailFormString: string | null;
  userDetailFormChanged: boolean | null;
  selectedProduct: any | null;
  agreementProduct: AgreementProduct | null;
  agreementProducts: AgreementProduct[];
  pageHistory: string[];
}

const initialCompany = JSON.parse(localStorage.getItem('company') || '{}') || {};

const initialState: DataState = {
  suppliers: {
    list: [],
    selected: initialCompany,
    fetching: false
  },
  errors: null,
  files: null,
  popover: null,
  product: '',
  order: null,
  sorting: null,
  filtering: null,
  agreementWithoutProducts: null,
  supplier_name: null,
  globalSettingsChanges: null,
  globalSettingsForm: null,
  suggestedDateRange: null,
  changeLogs: [],
  agreementFields: null,
  tableFilter: {
    products: {
      stock_below_the_minimum: null,
      product_with_orders_submitted: null,
      product_with_orders_delivered: null,
      search: null
    },
    production_demand: {
      stock_below_the_minimum: null,
      exclude_zero_plans: null,
      exclude_zero_orders: null,
      search: null
    },
    price_agreements: {
      status_valid: null,
      status_invalid: null,
      status_expired: null,
      status_draft: null,
      status_future: null,
      agreement_price_missing: null,
      product_price_missing: null,
      search: null,
      no_agreement: null,
      date: {
        search: null,
        start: null,
        end: null
      }
    },
    deliveries: {
      bound_with_invoices: null,
      submitted: null,
      delivered: null,
      search: null,
      date: {
        start: null,
        end: null
      }
    },
    spare_parts: {
      with_spares: null,
      search: null
    },
    documents: {
      search: null
    },
    warranty_cases: {
      new_cases: null,
      open: null,
      open_by_bmf: null,
      updates: null,
      case_status: null,
      bmf_status: null,
      search: null,
      date: {
        start: null,
        end: null
      }
    },
    merit_integration: {
      show_only_unbound: null,
      show_only_ignored: null,
      search: null
    },
    invoices: {
      search: null,
      show_unpaid: null,
      date: {
        start: null,
        end: null
      }
    },
    users: {
      search: null
    },
    orders: {
      search: null,
      delivered: null,
      undelivered: null,
      supplier_status: null
    },
    order_products: {
      with_deliveries: null,
      with_intakes: null
    }
  },
  warrantyDetailChanges: null,
  warrantyDetailsForm: null,
  warrantyDetailUpdates: {
    read: false,
    message: false,
    upload: false,
    expertise: false
  },
  detailCurrentTab: localStorage.getItem('detailCurrentTab') || '',
  fileDrag: null,
  userDetailFormString: null,
  userDetailFormChanged: null,
  selectedProduct: null,
  agreementProduct: null,
  agreementProducts: [],
  pageHistory:
    localStorage
      .getItem('pageHistory')
      ?.split(',')
      ?.filter((o) => o !== '') ?? []
};

export const fetchSuppliers = createAction('data/suppliers/fetching');

const data = createSlice({
  name: 'data',
  initialState,
  reducers: {
    _fetchSuppliers(state, action) {
      state.suppliers.list = action.payload.data;
      state.suppliers.fetching = false;
    },
    _setSuppliers(state, action) {
      state.suppliers.list = action.payload;
    },
    clearSearch(state) {
      state.tableFilter.products.search = null;
      state.tableFilter.production_demand.search = null;
      state.tableFilter.price_agreements.search = null;
      state.tableFilter.deliveries.search = null;
      state.tableFilter.spare_parts.search = null;
      state.tableFilter.documents.search = null;
      state.tableFilter.warranty_cases.search = null;
      state.tableFilter.merit_integration.search = null;
    },
    setDataError(state, action?: PayloadAction) {
      state.errors = action?.payload || null;
    },
    setFileUpload(state, action: PayloadAction<any>) {
      if (state.files) {
        state.files = [...state.files, ...action.payload];
      } else {
        state.files = action.payload;
      }
    },
    removeFiles(state) {
      state.files = null;
    },
    setPopoverAnchor(state, action?: PayloadAction<any>) {
      state.popover = action?.payload || null;
    },
    setProductName(state, action: PayloadAction<string>) {
      state.product = action.payload;
    },
    setOrderNumber(state, action: PayloadAction<any>) {
      state.order = action.payload;
    },
    setSorting(state, action: PayloadAction<any>) {
      state.sorting = action.payload;
    },
    setFiltering(state, action: PayloadAction<any>) {
      state.filtering = action.payload;
    },
    setAgreementWithoutProducts(state, action: PayloadAction<any>) {
      state.agreementWithoutProducts = action.payload;
    },
    setSupplierName(state, action: PayloadAction<any>) {
      state.supplier_name = action.payload;
    },
    setGlobalSettingsChange(state, action: PayloadAction<any>) {
      state.globalSettingsChanges = action.payload;
    },
    setGlobalSettingsForm(state, action: PayloadAction<any>) {
      state.globalSettingsForm = action.payload;
    },
    setSuggestedDateRange(state, action: PayloadAction<any>) {
      state.suggestedDateRange = action.payload;
    },
    setChangeLogs(state, action: PayloadAction<any>) {
      state.changeLogs = action.payload;
    },
    setTableFilter(state, action: PayloadAction<any>) {
      state.tableFilter = { ...state.tableFilter, ...action.payload };
    },
    setWarrantyDetailsChanges(state, action: PayloadAction<any>) {
      state.warrantyDetailChanges = action.payload;
    },
    setWarrantyDetailsForm(state, action: PayloadAction<any>) {
      state.warrantyDetailsForm = action.payload;
    },
    setWarrantyDetailUpdates(state, action: PayloadAction<any>) {
      const { type, value } = action.payload;
      state.warrantyDetailUpdates = { ...state.warrantyDetailUpdates, [type]: value };
    },
    updateWarrantyDetail(state, action: PayloadAction<any>) {
      state.warrantyDetailsForm = {
        ...state.warrantyDetailsForm,
        detail: action.payload
      } as WarrantyCase;
    },
    setDetailCurrentTab(state, action: PayloadAction<any>) {
      state.detailCurrentTab = action.payload;
      localStorage.setItem('detailCurrentTab', action.payload);
    },
    setFileDrag(state, action: PayloadAction<any>) {
      state.fileDrag = action.payload;
    },
    setUserDetailFormString(state, action: PayloadAction<any>) {
      state.userDetailFormString = action.payload;
    },
    setUserDetailFormChanged(state, action: PayloadAction<any>) {
      state.userDetailFormChanged = action.payload;
    },
    setSelectedProduct(state, action: PayloadAction<any>) {
      state.selectedProduct = action.payload;
    },
    setAgreementFields(state, action: PayloadAction<any>) {
      state.agreementFields = action.payload;
    },
    setAgreementProduct(state, action: PayloadAction<any>) {
      state.agreementProduct = action.payload;
    },
    setAgreementProducts(state, action: PayloadAction<any>) {
      state.agreementProducts = action.payload;
    },
    setPageHistory(state, action: PayloadAction<any>) {
      state.pageHistory = action.payload;
      localStorage.setItem('pageHistory', action.payload);
    }
  },
  extraReducers: {
    [fetchSuppliers.type]: (state) => {
      state.suppliers.fetching = true;
    }
  }
});

export const {
  _fetchSuppliers,
  _setSuppliers,
  clearSearch,
  setDataError,
  setFileUpload,
  removeFiles,
  setPopoverAnchor,
  setProductName,
  setOrderNumber,
  setSorting,
  setFiltering,
  setAgreementWithoutProducts,
  setSupplierName,
  setGlobalSettingsChange,
  setGlobalSettingsForm,
  setSuggestedDateRange,
  setChangeLogs,
  setTableFilter,
  setWarrantyDetailsChanges,
  setWarrantyDetailsForm,
  setWarrantyDetailUpdates,
  updateWarrantyDetail,
  setDetailCurrentTab,
  setFileDrag,
  setUserDetailFormString,
  setUserDetailFormChanged,
  setSelectedProduct,
  setAgreementFields,
  setAgreementProduct,
  setAgreementProducts,
  setPageHistory
} = data.actions;

export default data.reducer;
